.card-outer{
    width: 350px;
    height: 470px;
    background-color: rgb(192, 245, 179);
    border: 1px solid black;
    text-align: center;
    /* float: left; */
    margin-left: 15px;
    margin-bottom: 20px;
}
.btn{
    width: 100px;
    height: 30px;
    padding: 5px;
    font-weight: bolder;
    border-radius: 5px;
    background-color: black;
    color: white;
    box-shadow: 3px 3px 3px gray;
}
.available
{
    font-size: 25px;
    font-weight: bold;
    color: blue;
}
.outofstock
{
    font-size: 25px;
    font-weight: bold;
    color: red;
}
.singlestock
{
    font-size: 25px;
    font-weight: bold;
    color: brown;
}
.discount
{
    font-size: 25px;
    font-weight: bold;
    color: rgb(137, 74, 137);
}